<template>
  <div>
    <Alert />
    <b-card class="h-100" no-body>
      <b-card-header>
        <b-row class="mb-3">
          <b-col>
            <h3>{{ $t("views.surcharges.title") }}</h3>
          </b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col>
            <b-form-checkbox v-model="Enable" name="check-button" switch>{{
              $t("views.surcharges.ShowSurchargeEnable")
            }}</b-form-checkbox>
          </b-col>
          <b-col>
            <b-button-toolbar>
              <b-button-group class="ml-auto">
                <b-input
                  v-model="keyword"
                  size="sm"
                  :placeholder="$t('general.search')"
                />
                <b-button
                  v-if="keyword == ''"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                >
                  <b-icon-search />
                </b-button>
                <b-button
                  v-else
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('general.search')"
                  @click="keyword = ''"
                >
                  <b-icon-x />
                </b-button>
              </b-button-group>
              <b-button-group class="mx-1">
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.surcharges.AddSurcharge')"
                  @click="showAddSurcharge = !showAddSurcharge"
                >
                  <b-icon-plus />
                </b-button>
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.surcharges.EditSurcharge')"
                  :disabled="isSelected"
                  @click="EditSurcharge"
                >
                  <b-icon-pencil />
                </b-button>
                <b-button
                  v-show="Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.surcharges.RemoveSurcharge')"
                  :disabled="isSelected"
                  @click="RemoveSurcharge"
                >
                  <b-icon-trash />
                </b-button>
                <b-button
                  v-show="!Enable"
                  variant="primary"
                  v-b-tooltip.hover
                  size="sm"
                  :title="$t('views.surcharges.ActivateSurcharge')"
                  :disabled="isSelected"
                  @click="ActiveSurcharge"
                >
                  <b-icon-check />
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </b-col>
        </b-row>
      </b-card-header>

      <b-card-body class="overflow-auto p-0">
        <AddSurcharge class="p-3" v-bind:show.sync="showAddSurcharge" />
        <b-table
          ref="Surcharges"
          :busy="busy"
          :fields="fields"
          :items="surcharges"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="keyword"
          :filter-ignored-fields="ignoredFields"
          :selectable="selectable"
          small
          outlined
          select-mode="single"
          @row-selected="onRowSelected"
        >
          <template v-slot:table-busy>
            <div class="text-center text-primary my-2">
              <simple-loader />
            </div>
          </template>
          <template v-slot:cell(surchargeName)="data">
            <div v-if="!data.item.Edit">{{ data.value }}</div>
            <div v-else>
              <b-input
                v-model="data.item.surchargeName"
                block
                size="sm"
                :placeholder="$t('views.surcharges.EnterSurchargeName')"
              />
            </div>
          </template>
          <template v-slot:cell(enabled)="data">
            <div v-if="!data.item.Edit">
              <div v-if="data.value" class="text-success">
                <strong>
                  <b-icon-check />
                </strong>
              </div>
              <div v-else class="text-danger">
                <strong>
                  <b-icon-x />
                </strong>
              </div>
            </div>
            <b-row v-else>
              <b-col>
                <b-button
                  variant="success"
                  block
                  size="sm"
                  v-on:click="updateSurcharge()"
                  >{{ $t("general.save") }}</b-button
                >
              </b-col>
              <b-col>
                <b-button
                  variant="danger"
                  block
                  size="sm"
                  v-on:click="cancelUpdate()"
                  >{{ $t("general.cancel") }}</b-button
                >
              </b-col>
            </b-row>
          </template>
        </b-table>
      </b-card-body>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        pills
        size="sm"
        align="center"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AddSurcharge from "@/components/AddSurcharge";
import Alert from "@/components/general/alert";

export default {
  components: {
    AddSurcharge,
    Alert,
  },
  watch: {
    Enable() {
      this.busy = true;
      this.getSurcharges({
        session: this.$session,
        api: this.$api,
        enabled: this.Enable,
      })
        .then(() => {
          this.busy = false;
        })
        .catch((e) => {
          console.log(e);
          this.busy = false;
        });
    },
  },
  data() {
    return {
      Enable: true,
      perPage: 10,
      currentPage: 1,
      busy: false,
      selected: [],
      currentSurcharge: null,
      showAddSurcharge: false,
      keyword: "",
      selectable: true,
      oldName: "",
      ignoredFields: ["enabled"],
    };
  },
  methods: {
    ...mapActions("Surcharges", [
      "getSurcharges",
      "UpdateSurcharge",
      "ActivateSurcharge",
      "DeleteSurcharge",
    ]),
    toast(title, message) {
      this.$bvToast.toast(message, {
        title: title,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    onRowSelected(items) {
      this.selected = items;
    },
    EditSurcharge() {
      this.selected[0].Edit = true;
      this.currentSurcharge = this.selected[0];
      this.selectable = false;
      this.oldName = this.selected[0].surchargeName;
      this.$refs.Surcharges.refresh();
    },
    updateSurcharge() {
      const that = this;
      this.UpdateSurcharge({
        api: this.$api,
        session: this.$session,
        surcharge: this.currentSurcharge,
        idOU: this.$session.get("UnitOrganitaions"),
      })
        .then((resp) => {
          that.busy = false;
          that.toast(
            that.$t("views.surcharges.SurchargeUpdated"),
            resp.data.message
          );
          that.currentSurcharge.Edit = false;
          that.selectable = true;
          that.$refs.Surcharges.refresh();
        })
        .catch((e) => {
          console.log(e);
          that.busy = false;
          that.currentSurcharge.Edit = false;
          that.selectable = true;
          that.$refs.Surcharges.refresh();
        });
    },
    cancelUpdate() {
      this.currentSurcharge.surchargeName = this.oldName;
      this.currentSurcharge.Edit = false;
      this.selectable = true;
      this.$refs.Surcharges.refresh();
    },
    ActiveSurcharge() {
      this.selected[0].enable = false;
      const that = this;
      this.ActivateSurcharge({
        api: this.$api,
        session: this.$session,
        idSurcharge: this.selected[0].idSurcharge,
        idOU: this.$session.get("UnitOrganitaions"),
      })
        .then((resp) => {
          that.busy = false;
          that.$refs.Surcharges.refresh();
          that.toast(
            that.$t("views.surcharges.SurchargeActivated"),
            resp.data.message
          );
        })
        .catch((e) => {
          console.log(e);
          that.busy = false;
        });
    },
    RemoveSurcharge() {
      const that = this;
      this.DeleteSurcharge({
        api: this.$api,
        session: this.$session,
        idSurcharge: this.selected[0].idSurcharge,
        idOU: this.$session.get("UnitOrganitaions"),
      })
        .then((resp) => {
          that.busy = false;
          that.$forceUpdate();
          that.$refs.Surcharges.refresh();
          that.toast(
            that.$t("views.surcharges.SurchargeRemoved"),
            resp.data.message
          );
        })
        .catch((e) => {
          console.log(e);
          that.busy = false;
        });
    },
  },
  mounted() {
    this.busy = true;
    this.getSurcharges({
      session: this.$session,
      api: this.$api,
      enabled: true,
    })
      .then(() => {
        this.busy = false;
      })
      .catch((e) => {
        console.log(e);
        this.busy = false;
      });
  },
  computed: {
    ...mapGetters("Surcharges", ["surcharges"]),
    fields() {
      return [
        {
          key: "idSurcharge",
          sortable: true,
          label: this.$t("views.surcharges.fields.Id"),
        },
        {
          key: "surchargeName",
          sortable: true,
          label: this.$t("views.surcharges.fields.Name"),
        },
        {
          key: "enabled",
          sortable: false,
          label: this.$t("views.surcharges.fields.Enabled"),
        },
      ];
    },
    rows() {
      return this.items.length;
    },
    isSelected() {
      return this.selected.length < 1;
    },
    items() {
      return this.keyword
        ? this.surcharges.filter((item) =>
            item.surchargeName.includes(this.keyword)
          )
        : this.surcharges;
    },
  },
};
</script>

<style lang="scss" scoped></style>
