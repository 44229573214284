<template>
  <b-form class="my-3" @submit="onSubmit" @reset="onReset" v-if="show">
    <b-row v-if="busy">
      <b-col class="text-center">
        <simple-loader />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col xs="12" sm="8" md="9" lg="9" xl="9">
        <label class="sr-only">{{ $t("general.name") }}</label>
        <b-input
          v-model="SurchargeName"
          :placeholder="$t('views.surcharges.EnterSurchargeName')"
          cols="12"
          size="sm"
        ></b-input>
      </b-col>
      <b-col>
        <b-button variant="success" type="submit" block size="sm">{{
          $t("general.save")
        }}</b-button>
      </b-col>
      <b-col>
        <b-button variant="danger" type="reset" block size="sm">{{
          $t("general.cancel")
        }}</b-button>
      </b-col>
      <!-- <b-form-checkbox v-model="checked" name="check-button" switch>
        Enable
      </b-form-checkbox>-->
    </b-row>
  </b-form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      require: true,
    },
  },
  data() {
    return {
      SurchargeName: "",
      busy: false,
    };
  },
  methods: {
    ...mapActions("Surcharges", ["AddSurcharge"]),
    ...mapGetters("Surcharges", ["surcharges"]),
    onSubmit(oEvent) {
      oEvent.preventDefault();
      const that = this;
      that.busy = true;
      that
        .AddSurcharge({
          api: that.$api,
          session: that.$session,
          sName: that.SurchargeName,
          idOU: this.$session.get("UnitOrganitaions"),
        })
        .then((resp) => {
          that.resetForm();
          this.$bvToast.toast(resp.data.message, {
            title: this.$t("views.surcharges.SurchargeAdded"),
            autoHideDelay: 5000,
            appendToast: true,
          });
        })
        .catch(() => {
          that.resetForm();
        });
    },
    onReset(oEvent) {
      oEvent.preventDefault();
      this.resetForm();
    },
    resetForm() {
      this.busy = false;
      // this.show = false;
      this.$emit("update:show", false);
      this.SurchargeName = "";
    },
  },
};
</script>

<style></style>
